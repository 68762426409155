* {
  /* make the height and width include padding and border, not just margin */
  box-sizing: border-box;
}

body {
  /* make the default margin 0px for all elements */
  margin: 0;
  /*
    style="overflow-x: hidden" removes the horizontal scrollbar on mobile web
    note that <html> of index.html also has this
  */
  /*overflow-x: hidden;*/
  /* the font stack for Material Design
  note that the font stack for reddit is: 'Verdana', 'Aerial', 'Helvetica', sans-serif */
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

/*when it is 600px or less / when it is on mobile */
@media (max-width: 37.5rem) {
  body {
    /*moved overflow-x: hidden from main body {} above to here because having it
    on desktop interferes with position: sticky of PromptSidebar */
    overflow-x: hidden;
  }
}


/*62.5% of 16px (default browser font size) is 10px,
which is used to calculate all the rem values below*/
/* html {
  font-size: 62.5%;
}*/

/* body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
} */
